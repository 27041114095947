import { graphql } from "gatsby"
import React from "react"
import ImageBanner from "../components/ImageBanner"
import Layout from "../components/Layout"
import Products from "../components/Products"
import Seo from "../components/Seo"

const OurProducts = ({ data }) => {
  const { strapiProductsPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Our Products" />
      <ImageBanner heading={info.banner_heading} img={info.banner_image.url} />
      <Products img={info.products} />
    </Layout>
  )
}

export const data = graphql`
  {
    strapiProductsPage {
      banner_heading
      banner_image {
        url
      }
      products {
        product_name
        product_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

export default OurProducts

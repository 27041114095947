import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { SRLWrapper } from "simple-react-lightbox"

const Products = ({ img }) => {
  return (
    <section className="my-5 products container-fluid">
      <SRLWrapper>
        <Row>
          {img.map((item, i) => {
            const path = getImage(item?.product_image?.localFile)
            return (
              <Col key={i} lg={4}>
                <GatsbyImage
                  image={path}
                  alt={item.product_name}
                  className="product-img"
                />
              </Col>
            )
          })}
        </Row>
      </SRLWrapper>
    </section>
  )
}

export default Products
